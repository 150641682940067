import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import moment from 'moment';

// Components
import DiscountedIcon from '../icons/discounted-icon';

import { ListView } from '../events/listView';
import TimeArrow from '../text/timeArrow';
import InfoIcon from '../icons/info-icon';
import DummyEvent from '../icons/dummy-event';

import Title from '../text/title';
import Textbox from '../text/textbox';
import SingleImage from '../images/singleImage';
import ContentContainer from '../global/contentContainer';

// Utils

import { formatTitle } from '../utility/formatTitle';
import formatPrice from '../utility/formatPrice';
import { Line } from '../utility/line';

const Information = styled.div`
  font-size: 30px;

  display: flex;
  justify-content: center;

  color: ${props => props.color};

  margin: 0 0 65px 0;

  & > p {
    margin: 0 30px 0 0;
  }

  & > a {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }

  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const Date = styled.p``;

const PriceContainer = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  margin: 0;
  color: ${props => props.color};

  & > p:nth-child(2) {
    margin-left: 20px;
  }

  @media (max-width: 767px) {
    margin: 0 9px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const TextContainer = styled.div`
  font-size: 20px;
  & a {
    text-decoration: none;
  }

  & p {
    margin: 0;
  }

  margin: ${props => props.bottomMargin || '0 9px'};

  @media (max-width: 767px) {
    margin: 0 9px 20px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const TutionCategory = styled.span`
  &:after {
    content: ', ';
    display: inline;
  }

  &:last-of-type:after {
    content: '';
  }
`;

const EventsContainer = styled.div`
  margin: 75px 0;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

const Price = styled.p`
  margin: 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  :hover {
    filter: invert(100%);
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const ActivePrice = styled.p`
  margin: 0;
  cursor: pointer;

  :hover {
    filter: invert(100%);
  }

  display: block;
  border-bottom: 2px solid ${props => props.textColor};
  padding-top: 3px;
`;

const AddToCart = styled.div`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 20px;

  :hover {
    filter: invert(100%);
  }

  & svg {
    margin-left: 5px;
  }
`;

const SoldOutPriceContainer = styled(PriceContainer)``;

const SoldOutPrice = styled(Price)`
  cursor: initial;

  :hover {
    filter: unset;
  }
`;

const SoldOutAddToCart = styled(AddToCart)`
  cursor: initial;

  :hover {
    filter: unset;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const OnePlaceLeft = styled.p`
  width: auto;
  position: relative;
  display: inline;

  color: #000;
`;

const Theme = {
  highlightColor: '#3c82e6',
  textColor: '#ffffff',
};

const ContainerTheme = {
  backgroundColor: '#ebfaff',
};

const Event = ({ data }) => {
  const [currentTime, setCurrentTime] = useState();

  const [product, setProduct] = useState([]);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [activePrice, setActivePrice] = useState('full_price');

  useEffect(() => {
    setCurrentTime(moment());
  }, [setCurrentTime]);

  useEffect(() => {
    const formattedProductData = {
      name:
        activePrice === 'full_price'
          ? data.prismicEvent.data.title.text
          : `${data.prismicEvent.data.title.text} – Members Discount`,
      id:
        activePrice === 'full_price'
          ? `sku_${data.prismicEvent.prismicId}`
          : `sku_${data.prismicEvent.prismicId}_discounted`,
      price:
        activePrice === 'full_price'
          ? data.prismicEvent.data.full_price * 100
          : data.prismicEvent.data.discounted_price * 100,
      currency: 'GBP',
      image: data.prismicEvent.data.image,
      uid: `/timetable/event/${data.prismicEvent.uid}`,
      details: data.prismicEvent.data,
      productType: `event`,
    };

    setProduct(formattedProductData);
  }, [data, activePrice, setProduct]);

  // useEffect(() => {
  //   getStocksLevels();
  // }, []);

  useEffect(() => {
    const formattedRelatedEvents = data.prismicEvent.data.events_list
      .filter(event => event.events !== null)
      .filter(event => event.events.document !== null)
      .filter(event => {
        let eventDateTime = moment(
          event.events.document.data.rawDate +
            ' ' +
            event.events.document.data.start_time
        );
        let pastEventCheck = moment(currentTime).isSameOrAfter(eventDateTime);
        return pastEventCheck === false;
      })
      .map(event => (event = { node: event.events.document }));

    if (formattedRelatedEvents.length !== 0) {
      setRelatedEvents(formattedRelatedEvents);
    }

    if (formattedRelatedEvents.length === 0) {
      const formattedAutoEvents = data.allPrismicEvent.edges.filter(event => {
        let eventDateTime = moment(
          event.node.data.rawDate + ' ' + event.node.data.start_time
        );
        let pastEventCheck = moment(currentTime).isSameOrAfter(eventDateTime);
        return pastEventCheck === false;
      });
      setRelatedEvents(formattedAutoEvents);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{formatTitle(data.prismicEvent.data.title.text)}</title>
        <meta
          name="title"
          content={formatTitle(data.prismicEvent.data.title.text)}
        />
        <meta name="description" content={data.prismicEvent.data.text.text} />
        <link
          rel="canonical"
          href={
            'https://www.draw-brighton.co.uk/timetable/event/' +
            data.prismicEvent.uid
          }
        />
        <meta
          property="og:url"
          content={
            'https://www.draw-brighton.co.uk/timetable/event/' +
            data.prismicEvent.uid
          }
        />
        <meta property="og:image" content={data.prismicEvent.data.image.url} />
        <meta
          property=" og:image:secure_url"
          content={data.prismicEvent.data.image.url}
        />
        <meta
          property="og:description"
          content={data.prismicEvent.data.text.text}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en" />
        <meta
          name="twitter:title"
          content={formatTitle(data.prismicEvent.data.title.text)}
        />
        <meta
          name="twitter:description"
          content={data.prismicEvent.data.text.text}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={data.prismicEvent.data.image.url} />
      </Helmet>

      <Row justifyContent="center" css={ContainerTheme}>
        <Col col={12} md={10}>
          <ContentContainer
            padding={'0 36px'}
            mobilePadding={'40px 9px 20px 9px'}
          >
            <Title text={formatTitle(data.prismicEvent.data.title.text)} />
          </ContentContainer>
        </Col>

        <Col col={12} md={10} lg={8}>
          <ContentContainer padding={'0 36px'} mobilePadding={'0 9px'}>
            <Information color={Theme.highlightColor}>
              <Date>
                <time dateTime={data.prismicEvent.data.date}>
                  {data.prismicEvent.data.date}
                </time>
                {data.prismicEvent.data.end_date && (
                  <>
                    <TimeArrow> →</TimeArrow>{' '}
                    <time dateTime={data.prismicEvent.data.end_date}>
                      {data.prismicEvent.data.end_date}{' '}
                    </time>
                  </>
                )}
              </Date>
              <Link to={'/about/tutor/' + data.prismicEvent.data.tutor.uid}>
                {data.prismicEvent.data.tutor.document.data.name.text}
              </Link>
            </Information>
            <SingleImage image={data.prismicEvent.data.image.url} />
          </ContentContainer>
        </Col>

        <Textbox
          text={data.prismicEvent.data.text.html}
          singleTextboxMargin={'0 0 1em 0'}
          mobileBottomMargin={'0 9px 20px 9px'}
        />
        <Textbox
          text={
            'Time: ' +
            data.prismicEvent.data.start_time +
            ' → ' +
            data.prismicEvent.data.finish_time
          }
          bottomMargin={'20px 0 0 0'}
          mobileBottomMargin={'0 9px'}
        />

        <Col col={12} md={10} lg={10}>
          <Row justifyContent="center">
            <Col col={12} sm={9} md={9} lg={7} xl={6}>
              <TextContainer bottomMargin={'0 0 20px 0'}>
                Location:{' '}
                <Link
                  to={'/about/location/' + data.prismicEvent.data.location.uid}
                >
                  {data.prismicEvent.data.location.document.data.title.text}
                </Link>
                <p>
                  Tuition:{' '}
                  {data.prismicEvent.data.tuition_categories
                    .filter(tuitionType => tuitionType.tuition !== null)
                    .filter(
                      tuitionType => tuitionType.tuition.document !== null
                    )
                    .map((tuitionType, index) => {
                      return (
                        <TutionCategory key={index}>
                          {tuitionType.tuition.document.data.title.text}
                        </TutionCategory>
                      );
                    })}
                </p>
              </TextContainer>
            </Col>
          </Row>
        </Col>

        <Col col={12} md={10} lg={10}>
          <Row justifyContent="center">
            <Col col={12} sm={9} md={9} lg={7} xl={6}>
              <>
                {data.prismicEvent.data.full_price <= 0 && (
                  <PriceContainer color={Theme.highlightColor}>
                    <Price color={Theme.textColor}>FREE</Price>
                    {data.prismicEvent.data.eventbrite_link.url !== '' && (
                      <AddToCart>
                        <a
                          href={data.prismicEvent.data.eventbrite_link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Book Here
                        </a>
                      </AddToCart>
                    )}
                  </PriceContainer>
                )}

                {data.prismicEvent.data.full_price > 0 &&
                  data.prismicEvent.data.partner_event === 'True' && (
                    <PriceContainer color={Theme.highlightColor}>
                      <Price>
                        {formatPrice(data.prismicEvent.data.full_price, 'gbp')}
                      </Price>
                      {data.prismicEvent.data.discounted_price && (
                        <Price>
                          (
                          <DiscountedIcon fill={Theme.highlightColor} />{' '}
                          {formatPrice(
                            data.prismicEvent.data.discounted_price,
                            'gbp'
                          )}
                          )
                        </Price>
                      )}
                      {data.prismicEvent.data.eventbrite_link.url !== '' && (
                        <AddToCart>
                          <a
                            href={data.prismicEvent.data.eventbrite_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Book Here
                          </a>
                        </AddToCart>
                      )}
                    </PriceContainer>
                  )}

                {data.prismicEvent.data.full_price > 0 &&
                  data.prismicEvent.data.partner_event !== 'True' && (
                    <PriceContainer color={Theme.highlightColor}>
                      {activePrice === 'full_price' ? (
                        <ActivePrice
                          color={Theme.textColor}
                          onClick={() => setActivePrice('full_price')}
                        >
                          {formatPrice(
                            data.prismicEvent.data.full_price,
                            'gbp'
                          )}
                        </ActivePrice>
                      ) : (
                        <Price onClick={() => setActivePrice('full_price')}>
                          {formatPrice(
                            data.prismicEvent.data.full_price,
                            'gbp'
                          )}
                        </Price>
                      )}

                      {data.prismicEvent.data.discounted_price && (
                        <>
                          {activePrice === 'discounted_price' ? (
                            <ActivePrice
                              color={Theme.textColor}
                              onClick={() => setActivePrice('discounted_price')}
                            >
                              (
                              <DiscountedIcon
                                fill={Theme.highlightColor}
                              />{' '}
                              {formatPrice(
                                data.prismicEvent.data.discounted_price,
                                'gbp'
                              )}
                              )
                            </ActivePrice>
                          ) : (
                            <Price
                              onClick={() => setActivePrice('discounted_price')}
                            >
                              (
                              <DiscountedIcon
                                fill={Theme.highlightColor}
                              />{' '}
                              {formatPrice(
                                data.prismicEvent.data.discounted_price,
                                'gbp'
                              )}
                              )
                            </Price>
                          )}
                        </>
                      )}

                      {data.prismicEvent.data.eventbrite_link.url !== '' && (
                        <AddToCart>
                          <a
                            href={data.prismicEvent.data.eventbrite_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Book Here
                          </a>
                        </AddToCart>
                      )}
                    </PriceContainer>
                  )}
              </>
            </Col>
          </Row>
        </Col>

        {relatedEvents.length >= 1 ? (
          <>
            <Col col={12}>
              <Line
                margin={'75px 36px 0'}
                mobileMargin={'40px 9px 0'}
                color={Theme.highlightColor}
              />
            </Col>

            <Col col={12}>
              <ContentContainer padding={'0 36px'}>
                <EventsContainer>
                  <Row>
                    <ListView
                      allEvents={relatedEvents}
                      color={Theme.highlightColor}
                      textColor={Theme.textColor}
                    />

                    {/* {numberOfDummyEvents <= 4 && dummyEvents} */}
                  </Row>
                </EventsContainer>
              </ContentContainer>
            </Col>
          </>
        ) : (
          <Col col={12}>
            <Line
              margin={'75px 36px 0'}
              mobileMargin={'40px 9px 0'}
              color={`transparent`}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default Event;

export const pageQuery = graphql`
  query EventBySlug($uid: String!, $formattedRegex: String) {
    prismicEvent(uid: { eq: $uid }) {
      uid
      prismicId
      data {
        subject_categories {
          subject {
            id
            document {
              ... on PrismicSubject {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        rawDate: date
        rawEndDate: end_date
        date(formatString: "DD/MM/YY")
        end_date(formatString: "DD/MM/YY")
        partner_event
        eventbrite_link {
          url
        }
        tutor {
          uid
          document {
            ... on PrismicTutors {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        location {
          uid
          document {
            ... on PrismicLocation {
              data {
                title {
                  text
                }
              }
            }
          }
        }

        start_time
        finish_time
        tuition_categories {
          tuition {
            document {
              ... on PrismicTuition {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        image {
          dimensions {
            width
            height
          }
          url
          fluid {
            srcWebp
          }
        }
        text {
          html
          text
        }
        full_price
        discounted_price
        initial_number_of_places
        events_list {
          events {
            document {
              ... on PrismicEvent {
                uid
                prismicId
                data {
                  title {
                    text
                  }
                  image {
                    alt
                    fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                  rawDate: date
                  rawEndDate: end_date
                  date(formatString: "ddd, DD MMM ‘YY")
                  end_date(formatString: "ddd, DD MMM ‘YY")
                  partner_event
                  eventbrite_link {
                    url
                  }
                  tutor {
                    url
                    document {
                      ... on PrismicTutors {
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                  }
                  location {
                    url
                    document {
                      ... on PrismicLocation {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  start_time
                  finish_time
                  full_price
                  discounted_price
                }
              }
            }
          }
        }
      }
    }
    allPrismicEvent(
      filter: {
        data: { title: { text: { regex: $formattedRegex } } }
        uid: { ne: $uid }
      }
      limit: 4
      sort: { order: ASC, fields: [data___date, data___start_time] }
    ) {
      edges {
        node {
          uid
          prismicId
          data {
            subject_categories {
              subject {
                id
                document {
                  ... on PrismicSubject {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            title {
              text
            }
            eventbrite_link {
              url
            }
            rawDate: date
            date(formatString: "ddd, DD MMM ‘YY")
            tutor {
              uid
              document {
                ... on PrismicTutors {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            location {
              uid
              document {
                ... on PrismicLocation {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            start_time
            finish_time
            tuition_categories {
              tuition {
                document {
                  ... on PrismicTuition {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            image {
              alt
              fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                srcWebp
                srcSetWebp
              }
            }
            full_price
            discounted_price
            initial_number_of_places
          }
        }
      }
    }
  }
`;
